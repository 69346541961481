<template>
  <div ref="app" id="app">
    <app-loading/>
    <browser-warning/>
    <router-view :key="$route.fullPath"/>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import pokerCommon from '@/pokerCommon';

export default {
  computed: {
    ...mapGetters('config', ['volume', 'sound']),
  },
  watch: {
    volume: {
      immediate: true,
      handler() {
        pokerCommon.changeVolume(this.volume);
      }
    }
  },
  methods: {
    updateAppVisibility() {
      if (document.hidden) {
        this.$store.commit('UPDATE_TIMESTAMP_ON_HIDDEN');
      }

      this.$store.commit('SET_APP_VISIBILITY', document.visibilityState);
    },
    init() {
      this.initSoundConfig();
    },
    initSoundConfig() {
      pokerCommon.loadHowler(() => {
        pokerCommon.changeVolume(this.volume);
        pokerCommon.isSoundOn = this.sound;
      });
    },
    changeFavicon() {
      const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
      link.type = 'image/x-icon';
      link.rel = 'shortcut icon';
      link.href = '/favicon.ico';
      document.getElementsByTagName('head')[0].appendChild(link);
    }
  },
  beforeCreate() {
    document.title = 'ROYAL HOLDEM';
  },
  created() {
    this.init();
  },
  mounted() {
    document.addEventListener('visibilitychange', this.updateAppVisibility);
    this.changeFavicon();
  },
  destroyed() {
    document.removeEventListener('visibilitychange', this.updateAppVisibility);
  },
}
</script>

<style lang="scss">
#app {
  margin: 0;
  // background: #000;
}
</style>
