// import './utils/preload'
import 'es6-promise/auto'
import Vue from 'vue'
import './plugins/bootstrap'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import store from './store'
import moment from 'moment'
import VueSocketIO from 'vue-socket.io'

import {EventBus} from '@/eventBus'
import VueSlider from 'vue-slider-component'
import * as gameCommon from './gameCommon'
import 'vue-slider-component/theme/antd.css'
import {pokerErrorProc} from './exceptionMsg'
import anime from 'animejs/lib/anime.es'
import loggerPlugin from './plugins/logger';
import filtersPlugin from './plugins/filters';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

const isDev = (process.env.NODE_ENV === "development");

Vue.config.devtools = isDev;
Vue.config.productionTip = isDev;

Vue.component('AppLoading', () => import(/* webpackChunkName: "app-loading", webpackPreload: true */ '@/components/AppLoading.vue'));
Vue.component('AppModal', () => import(/* webpackChunkName: "app-modal", webpackPrefetch: true */ '@/components/Modal.vue'));
Vue.component('BrowserWarning', () => import(/* webpackChunkName: "app-browser-warning", webpackPrefetch: true */ '@/components/BrowserWarning.vue'));
Vue.component('VueSlideBar', VueSlider);

Vue.prototype.moment = moment
Vue.prototype.EventBus = EventBus
Vue.prototype.playState = gameCommon.PlayState
Vue.prototype.roomState = gameCommon.RoomState
Vue.prototype.gameStatus = gameCommon.GameStatus
Vue.prototype.errorProc = pokerErrorProc
Vue.prototype.$anime = anime

store.state.window.w = window.innerWidth;
store.state.window.h = window.innerHeight;
window.addEventListener('resize', () => {
    store.state.window.w = window.innerWidth;
    store.state.window.h = window.innerHeight;
    EventBus.$emit('WindowResize');
});

const loginToken = sessionStorage.getItem('loginToken')
store.state.loginToken = loginToken || undefined

const swalOptions = {
    confirmButtonText: "확인",
    showCancelButton: false,
    dangerMode: true,
    allowOutsideClick:false,
};

Vue.use(VueSweetalert2, swalOptions);
Vue.use(loggerPlugin);
Vue.use(filtersPlugin);
Vue.use(new VueSocketIO({
    debug: isDev,
    connection: '',
    vuex: {
        store,
        actionPrefix: 'SOCK_',
        mutationPrefix: 'SOCK_'
    },
    options: {
        autoConnect: false,
        reconnection: true,
        secure: /^https/.test(window.location.protocol),
        transportOptions: {
            polling: {
                extraHeaders: {
                    Authorization: store.state.loginToken
                }
            }
        },
        refreshToken: function(){
            this.transportOptions.polling.extraHeaders.Authorization = store.state.loginToken;
        },
    }
}));

new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app');