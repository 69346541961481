import Vue from 'vue'
import VueRouter from 'vue-router'
import routesV2 from './routes-v2';
import store from "../store";

Vue.use(VueRouter)

const routes = [
    // v2
    ...routesV2,
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {

    //로딩 타임아웃설정
    const AppLoadingTimeoutHandler = setTimeout(() => {
        store.commit('UPDATE_IS_SHOW_APP_LOADING', true);
    }, 500);

    next();

    // 라우트 전환 완료 후 로딩 상태 해제
    router.afterEach(() => {
        clearTimeout(AppLoadingTimeoutHandler);
        store.commit('UPDATE_IS_SHOW_APP_LOADING', false);
    });
})

export default router