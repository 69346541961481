import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import {EventBus} from '../eventBus';
import gameStore from './game';
import userStore from './user';
import configStore from './config';
import emotionStore from './emotion';
import {setNickname} from "../plugins/apiHelper_game";

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        game: gameStore,
        user: userStore,
        emotion: emotionStore,
        config: configStore,
    },
    state: {
        loginToken: undefined,
        sessionData: undefined,
        isSeamlessUser: false,
        window: {w: 0, h: 0},
        rsvFold: false,
        rsvCall: false,
        sound: false,
        maintenanceNotice: undefined,
        isAnyModalOpened: false,
        isConnected: false,
        isOnline: false,
        networkUnavailable: false,
        networkErrorCount: 0,
        isNotSetPlayerName: false,
        appVisibility: 'visible',
        timestampOnHidden: 0,
        CLIENT_APP_HIDDEN_TIMEOUT: 5000,
        isShowAppLoading: false,
    },
    getters: {
        token: state => state.loginToken,
        sessionData: state => state.sessionData,
        appVisibility: state => state.appVisibility,
        timestampOnHidden: state => state.timestampOnHidden,
        isOnline: state => state.isOnline,
        isConnected: state => state.isConnected,
        networkErrorCount: state => state.networkErrorCount,
        networkUnavailable: state => state.networkUnavailable,
        maintenanceNotice: state => state.maintenanceNotice,
        isAnyModalOpened: state => state.isAnyModalOpened,
        isSetPlayerName: state => state.sessionData && (state.sessionData.name || '').toString().trim().length,
        appHiddenTimeout: state => state.CLIENT_APP_HIDDEN_TIMEOUT,
        isSeamlessUser: state => state.isSeamlessUser,
        isShowAppLoading: state => state.isShowAppLoading,
    },
    mutations: {
        login(state, data) {
            state.sessionData = data.info
            state.loginToken = data.loginToken
        },
        SET_SESSION_DATA(state, data) {
            state.sessionData = data;
        },
        SET_LOGIN_TOKEN(state, loginToken) {
            sessionStorage.setItem(`loginToken`, loginToken);
            state.loginToken = loginToken;
        },
        SET_IS_SEAMLESS_USER(state, isEnabled) {
            state.isSeamlessUser = isEnabled;
        },
        SET_IS_ANY_MODAL_OPENED(state, value) {
            state.isAnyModalOpened = value;
        },
        SET_IS_CONNECTED(state, status) {
            state.isConnected = status;
        },
        SET_IS_ONLINE(state, status) {
            state.isOnline = status;
        },
        SET_IS_NETWORK_UNSTABLE(state, status) {
            state.networkUnavailable = status;
        },
        RESET_NETWORK_ERROR_COUNT(state) {
            state.networkErrorCount = 0;
        },
        INCREASE_NETWORK_ERROR_COUNT(state) {
            if (state.networkErrorCount > 3) {
                state.isConnected = false;
                return;
            }

            state.networkErrorCount++;
        },
        SET_APP_VISIBILITY(state, visibleState) {
            state.appVisibility = visibleState;
        },
        UPDATE_TIMESTAMP_ON_HIDDEN(state) {
            state.timestampOnHidden = new Date().getTime();
        },
        UPDATE_IS_SHOW_APP_LOADING(state, isShow) {
            state.isShowAppLoading = isShow
        },
    },
    actions: {
        show_wrap_loading(){
            document.querySelector('#loadingWrap').style.display = '';
        },
        hide_wrap_loading(){
            setTimeout(() => {
                document.querySelector('#loadingWrap').style.display = 'none';
            }, 100);
        },
        async auth_login({commit}, data) {
            commit('SET_SESSION_DATA', data.info);
            commit('SET_LOGIN_TOKEN', data.loginToken);
            commit('SET_IS_SEAMLESS_USER', data.isSeamlessUser);
        },
        auth_logout({commit}){
            commit('SET_SESSION_DATA', '');
            commit('SET_LOGIN_TOKEN', '');
            commit('SET_IS_SEAMLESS_USER', false);
        },
        auth_check({dispatch}) {
            if (!this.state.loginToken) return false
            return axios.post('/auth/check', {loginToken: this.state.loginToken}).then(res => {
                if (res.data.ret == 0) dispatch('auth_login', res.data)
                return res.data.ret == 0
            })
        },
        auth_sync() {
            if (!this.state.loginToken) return false
            return axios.post('/auth/sync', {loginToken: this.state.loginToken}).then(res => {
                return res.data.ret === 0
            })
        },
        SOCK_poker(ctx, data) {
            EventBus.$emit(`poker-${data.type}`, data.body);
        },
        SOCK_token({state}, token) {
            state.loginToken = token;
        },
        SOCK_duplicated() {
            EventBus.$emit('socket-duplicated')
        },
        SOCK_cut() {
            EventBus.$emit('socket-cut')
        },
        SOCK_maintenance(ctx, data) {
            this.state.maintenanceNotice = data.body.msg
        },
        SOCK_common(ctx, data) {
            EventBus.$emit(`common-${data.type}`, data.body);
        },
        async setPlayerName({state}, name) {
            return await setNickname({loginToken: state.loginToken, name});
        }
    },
})
