import store from "../store";

const requireAuth = () => async (to, from, next) => {
    if (to.query.token) {
        // localStorage.setItem(`loginToken`, to.query.token);
        // store.state.loginToken = to.query.token
        store.commit('SET_LOGIN_TOKEN', to.query.token);
    }

    if (from.query.token) {
        // localStorage.setItem(`loginToken`, from.query.token);
        // store.state.loginToken = from.query.token
        store.commit('SET_LOGIN_TOKEN', from.query.token);
    }

    //심리스일경우
    if( store.state.isSeamlessUser && ['/', '/main', '/game', '/tournament'].includes(to.path) ) {
        await store.dispatch('auth_sync');
    }

    const isAuth = await store.dispatch('auth_check');

    if (isAuth || to.path === `/login`) {
        await store.dispatch('hide_wrap_loading');
        next();
    } else {
        next(`/login?return=${to.fullPath}`);
    }
}

export {requireAuth};