import {requireAuth} from "./middleware";

const isRequireLoginPage = (process.env.VUE_APP_REQUIRE_LOGIN_PAGE === 'true');

export default [
    // {
    //     path: '/',
    //     name: 'entrypoint',
    //     beforeEnter: requireAuth(),
    //     component: () => import(/* webpackChunkName: "entry-v2" */ '@/pages/Game/Entry.vue'),
    // },
    {
        path: '/',
        name: 'lobby',
        beforeEnter: requireAuth(),
        component: () => import(/* webpackChunkName: "lobby-v2" */ '@/pages/Game/Lobby.vue'),
    },
    {
        path: '/main',
        name: 'main',
        beforeEnter: requireAuth(),
        component: () => import(/* webpackChunkName: "lobby-v2" */ '@/pages/Game/Main.vue'),
    },
    {
        path: '/game',
        name: 'game',
        beforeEnter: requireAuth(),
        props: true,
        component: () => import(/* webpackChunkName: "game-v2" */ '@/pages/Game/Room.vue'),
    },
    {
        path: '/tournament',
        name: 'tournament',
        beforeEnter: requireAuth(),
        props: true,
        component: () => import(/* webpackChunkName: "tournament-v2" */ '@/pages/Game/Room.vue'),
    },
    {
        path: '/game/handhistory',
        name: 'handhistory',
        beforeEnter: requireAuth(),
        component: () => import(/* webpackChunkName: "handhistory-v2" */ '@/pages/Game/Menu/HandHistory.vue'),
    },
    {
        path: '/login',
        name: 'login',
        component: (isRequireLoginPage) ?
            () => import( /* webpackChunkName: "login-v2" */ '@/views/Login.vue') :
            () => import( /* webpackChunkName: "auth-gate-v2" */ '@/pages/Game/AuthGate.vue')
    },
    {
        path: '/loginByCode',
        name: 'loginByCode',
        component: () => import(/* webpackChunkName: "login-by-code-v2", webpackPreload: true */ '@/views/LoginByCode')
    },
    // {
    //     path: '/test',
    //     name: 'testpage',
    //     component: () => import(/* webpackChunkName: "test-page-v2" */ '@/pages/Test.vue'),
    // },
    {
        path: '*',
        redirect: '/main',
    },
];